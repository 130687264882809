import { Box, Typography } from '@mui/material';
import { EVENT, pushToDataLayer } from '@wr/web-shared';
import { getCookie } from 'cookies-next';
import React, { useEffect, useState } from 'react';

import { useAppContext } from '@/context';
import { useSendAmount } from '@/hooks';
import { CountryData } from '@/services/rest/country.service';
import { Segments } from '@/services/rest/pricing.service';
import {
  useCountriesStore,
  usePricingSegmentsStore,
  usePricingStore,
} from '@/state';
import { interpolate, logger } from '@/utils';

import { CountryField } from './country-field';
import { sortArrayByProperty } from './country-field/country-field.utils';
import { useStyles } from './exchange-calculator.styles';
import {
  ExchangeCalculatorProps,
  SendReceiveCountry,
  Types,
} from './exchange-calculator.types';
import { getInputValue, getUrlOverride } from './exchange-calculator.utils';
import { PaymentMethods } from './payment-methods';
import { AmountType } from './payment-methods/payment-methods.types';
import { Pricing } from './pricing';

export const ExchangeCalculator: React.FC<ExchangeCalculatorProps> = ({
  name,
  title,
  mobile,
  bank,
  cash,
  heading,
  description,
  sendLabel,
  receiveLabel,
  limitReachedError,
  exchangeRate,
}) => {
  const classes = useStyles();
  const {
    sendCountry: initSendCountry,
    receiveCountry: initReceiveCountry,
    analyticsPageType,
    locale,
  } = useAppContext();

  const amountTypeRef = React.useRef<AmountType>(Types.SEND);
  const sendCountriesRef = React.useRef<SendReceiveCountry>({});
  const receiveCountriesRef = React.useRef<SendReceiveCountry>({});
  const [sendCountry, setSendCountry] = React.useState<CountryData>();
  const [receiveCountry, setReceiveCountry] = React.useState<CountryData>();
  const [selectedSegment, setSelectedSegment] = React.useState<Segments>();
  const [paymentSegments, setPaymentSegments] = React.useState<
    Segments[] | undefined
  >([]);
  const [limitReached, setLimitReached] = React.useState(false);
  const [receiveCountries, setReceiveCountries] = React.useState<CountryData[]>(
    [],
  );

  const { countries, loading: countriesLoading } = useCountriesStore(
    state => state,
  );
  const { fetch: fetchRate, pricing, loading: isRateLoading } = usePricingStore(
    state => state,
  );
  const {
    pricingSegments,
    fetch: fetchPricingSegmentMethods,
    loading: isPricingSegmentsLoading,
  } = usePricingSegmentsStore(state => state);

  const {
    values,
    setValues,
    updateSendAmount,
    extractSendAmountFromUrl,
  } = useSendAmount();

  React.useEffect(() => {
    (() => {
      try {
        if (!countries) return;

        fetchAllCountries(countries);
      } catch (error) {
        logger.error(error, `srvdata query request failed`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, initSendCountry]);

  const fetchAllCountries = (countryData: CountryData[]) => {
    const sendCountries: SendReceiveCountry = {};
    const receiveCountries: SendReceiveCountry = {};

    countryData.forEach(country => {
      if (country.send) sendCountries[country.iso2] = country;
      if (country.receive) receiveCountries[country.iso2] = country;
    });

    const determineDefaultSendCountry = (): {
      sendCountry: CountryData;
      sendAmount: number;
    } => {
      const locationCountry = getCookie('locationCountry')?.toString();

      const { sendCountryCode, sendAmount } = getUrlOverride(
        sendCountries,
        initSendCountry,
        extractSendAmountFromUrl,
      );

      const lowerCaseUrlOverride = sendCountryCode?.toLowerCase();

      if (lowerCaseUrlOverride && sendCountries[lowerCaseUrlOverride]) {
        return {
          sendCountry: sendCountries[lowerCaseUrlOverride],
          sendAmount,
        };
      }

      if (locationCountry && sendCountries[locationCountry]) {
        return {
          sendCountry: sendCountries[locationCountry],
          sendAmount,
        };
      }

      if (initSendCountry && sendCountries[initSendCountry.iso2]) {
        return {
          sendCountry: initSendCountry,
          sendAmount,
        };
      }

      const firstAvailableCountry = Object.values(sendCountries)[0];
      return {
        sendCountry: firstAvailableCountry || sendCountries['US'],
        sendAmount,
      };
    };

    const {
      sendCountry: defaultSendCountry,
      sendAmount: urlSendAmount,
    } = determineDefaultSendCountry();
    const defaultReceiveCountryKey =
      initReceiveCountry?.iso2 ||
      defaultSendCountry.validReceiveCountriesIso2?.[0];

    sendCountriesRef.current = sendCountries;
    receiveCountriesRef.current = receiveCountries;

    setSendCountry(defaultSendCountry);
    if (defaultReceiveCountryKey) {
      setReceiveCountry(receiveCountries[defaultReceiveCountryKey]);
    }

    updateSendAmount(urlSendAmount);
  };

  const fixMultiplePayoutCurrency = React.useCallback(() => {
    let receiveCountriesArr: CountryData[] = [];

    if (sendCountry) {
      const validReceiveCountriesIso2 =
        sendCountriesRef.current[sendCountry?.iso2]?.validReceiveCountriesIso2;
      if (validReceiveCountriesIso2) {
        receiveCountriesArr = validReceiveCountriesIso2.flatMap(key => {
          const country = receiveCountriesRef.current[key];
          if (!country) return [];
          const paymentMethodCountries =
            country.payoutMethods?.flatMap(
              paymentMethod =>
                paymentMethod.currencies
                  ?.filter(currency => currency.currency !== country.currency)
                  .map(currency => ({ ...country, ...currency })) || [],
            ) || [];
          return [country, ...paymentMethodCountries];
        });
      }
    }

    const sortedReceiveCountriesArr = sortArrayByProperty(
      receiveCountriesArr,
      'name',
    );

    // Check if the current receiveCountry exists in the receiveCountries list
    // if not set the first item in the receiveCountries list as the current receiveCountry
    const isCurrentReceiveCountryValid = sortedReceiveCountriesArr.find(
      country => country.iso2 === receiveCountry?.iso2,
    );
    if (!isCurrentReceiveCountryValid && sortedReceiveCountriesArr.length > 0) {
      setReceiveCountry(sortedReceiveCountriesArr[0]);
    }

    setReceiveCountries(receiveCountriesArr);
  }, [sendCountry, receiveCountry]);

  const [fullUrl, setFullUrl] = useState('');
  useEffect(() => {
    setFullUrl(window.location.href);
  }, []);

  useEffect(() => {
    const GA_EVENT = {
      event: EVENT.CALCULATOR_INTERACTION,
      page_type: analyticsPageType,
      page_name: fullUrl,
      page_language: locale,
      send_currency: sendCountry?.currency,
      receive_currency: receiveCountry?.currency,
      sender_country_iso: sendCountry?.iso2.toUpperCase(),
      receive_country_iso: receiveCountry?.iso2.toUpperCase(),
    };
    pushToDataLayer(GA_EVENT);
  }, [sendCountry, receiveCountry, fullUrl, analyticsPageType, locale]);

  const handleSendReceiveChange = React.useCallback(
    (
      type: AmountType,
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      const inputValue = getInputValue(event.currentTarget.value);

      if (inputValue === '' || inputValue === null) {
        if (type === Types.SEND) {
          updateSendAmount(0);
        } else {
          setValues(prevValues => ({ ...prevValues, [type]: 0 }));
        }
        return;
      }

      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue)) {
        if (type === Types.SEND) {
          updateSendAmount(numericValue);
        } else {
          setValues(prevValues => ({
            ...prevValues,
            [type]: numericValue,
          }));
        }
      }
    },
    [updateSendAmount, setValues],
  );

  const handleSelectedSegmentChange = React.useCallback(
    (_event: React.MouseEvent<HTMLElement>, bestPricedSegment: Segments) => {
      setSelectedSegment(bestPricedSegment);
    },
    [],
  );

  const loading = countriesLoading || isPricingSegmentsLoading;

  const errorTemplate = {
    limit: sendCountry?.sendLimits?.default.day.toString() || '',
    currency: sendCountry?.currency || '',
  };

  const effectiveRate = pricing?.effectiveExchangeRate || 0;
  const receiveCurrency = Math.round(effectiveRate * 100) / 100;
  const currencyTemplate = {
    sendCountryCode: sendCountry?.currency || '',
    receiveCurrency: receiveCurrency.toString() || '',
    receiveCountryCode: receiveCountry?.currency || '',
  };

  return (
    <section className={classes.container} data-testid={'calculator-container'}>
      <Box className={classes.exchange}>
        <Typography className={classes.header}>{heading}</Typography>
        <Typography
          variant="h6"
          className={classes.exchangeRate}
          data-testid={'title-exchange-rate'}
        >
          {interpolate(exchangeRate, currencyTemplate)}
        </Typography>
      </Box>
      <Typography
        variant="h6"
        className={classes.title}
        data-testid={'calculator-title'}
      >
        {title}
      </Typography>
      <Typography className={classes.description}>{description}</Typography>
      <Box padding={4}>
        <Box mb={2}>
          <CountryField
            inputId={`${name}-send`}
            inputLabel={sendLabel || ''}
            placeholder="_"
            loading={loading}
            selectedCountry={sendCountry}
            inputValue={values.SEND}
            onChange={event => handleSendReceiveChange(Types.SEND, event)}
            countries={Object.keys(sendCountriesRef.current).map(
              key => sendCountriesRef.current[key],
            )}
            handleCountryChange={setSendCountry}
          />
          <CountryField
            inputId={`${name}-receive`}
            inputLabel={receiveLabel || ''}
            placeholder="_"
            loading={loading}
            countries={receiveCountries}
            selectedCountry={receiveCountry}
            inputValue={values.RECEIVE}
            onChange={event => handleSendReceiveChange(Types.RECEIVE, event)}
            handleCountryChange={setReceiveCountry}
          />
        </Box>
        <PaymentMethods
          isRateLoading={isRateLoading}
          receiveCountry={receiveCountry}
          sendCountry={sendCountry}
          isPricingSegmentsLoading={isPricingSegmentsLoading}
          countriesLoading={countriesLoading}
          pricingSegments={pricingSegments}
          setSelectedSegment={setSelectedSegment}
          setPaymentSegments={setPaymentSegments}
          fetchPricingSegmentMethods={fetchPricingSegmentMethods}
          images={[mobile, bank, cash]}
          isLoading={isPricingSegmentsLoading}
          selectePaymentSegmentMethod={selectedSegment}
          limitReached={limitReached}
          paymentMethodSegments={paymentSegments}
          handleChange={handleSelectedSegmentChange}
          locale={locale}
        />
        <Pricing
          isRateLoading={isRateLoading}
          countriesLoading={countriesLoading}
          amountTypeRef={amountTypeRef}
          pricing={pricing}
          values={values}
          receiveCountry={receiveCountry}
          sendCountry={sendCountry}
          selectedSegment={selectedSegment}
          limitReached={limitReached}
          setValues={setValues}
          setLimitReached={setLimitReached}
          fetchRate={fetchRate}
          fixMultiplePayoutCurrency={fixMultiplePayoutCurrency}
          locale={locale}
        />
        {limitReached && (
          <Typography className={classes.error}>
            {interpolate(limitReachedError, errorTemplate)}
          </Typography>
        )}
      </Box>
    </section>
  );
};
