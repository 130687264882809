import { Container, Dialog } from '@mui/material';
import React from 'react';
import YouTube from 'react-youtube';

import { onEnterOrSpacePress } from '../../utils/keyboard';
import { Container as UIContainer } from '../container';
import { Image } from '../image';
import PlayButton from './play-button';
import { useStyles } from './video.styles';
import { VideoProps } from './video.types';

const EmptyPaper: React.FC = ({ children }) => <div>{children}</div>;

export function Video<RichText>({
  name,
  content,
  backgroundImage,
  videoId,
  RichTextComponent,
  containerProps,
  isContained,
}: VideoProps<RichText>): React.ReactElement {
  const classes = useStyles();
  const opts = {
    width: '100%',
    height: '100%',
    playerVars: { autoplay: 1 },
  };
  const [isOpen, setOpen] = React.useState(false);
  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);
  const handleKeyDown = (event: React.KeyboardEvent): void =>
    onEnterOrSpacePress(event, handleOpen);

  const dialog = (
    <Dialog open={isOpen} onClose={handleClose} PaperComponent={EmptyPaper}>
      <YouTube className={classes.iframe} videoId={videoId} opts={opts} />
    </Dialog>
  );

  if (isContained) {
    return (
      <>
        <section
          id={name}
          className={classes.containedSection}
          role="button"
          tabIndex={0}
          onClick={handleOpen}
          onKeyDown={handleKeyDown}
          {...containerProps}
        >
          <UIContainer className={classes.containedSectionContainer}>
            {backgroundImage && <Image {...backgroundImage} />}
            <Container
              className={classes.containedContentContainer}
              maxWidth="sm"
            >
              <PlayButton isContained={true} />
            </Container>
          </UIContainer>
        </section>
        {dialog}
      </>
    );
  }

  return (
    <section id={name} className={classes.sectionWrapper} {...containerProps}>
      <div
        role="button"
        tabIndex={0}
        onClick={handleOpen}
        onKeyDown={handleKeyDown}
        className={classes.sectionContainer}
      >
        {backgroundImage && <Image {...backgroundImage} />}
        <Container className={classes.contentContainer} maxWidth="sm">
          <PlayButton />
          {content && (
            <div className={classes.content}>
              <RichTextComponent {...content} />
            </div>
          )}
        </Container>
      </div>
      {dialog}
    </section>
  );
}
