import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  card: () => ({
    width: '100%',
    height: '100%',
    border: theme.customComponents.card.border,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  textContainer: () => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    color: theme.customComponents.card.textContainer.color,
  }),
  cardIconContainer: {
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  cardIcon: {
    width: 'auto',
    height: 'auto',
    maxWidth: theme.spacing(5),
    maxHeight: theme.spacing(5),
  },
  cardLinkTitle: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  cardLink: {
    color: theme.palette.text.secondary,
    textDecorationColor: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(2),
  },
}));
