export const getRecpatchaToken = async (): Promise<string> => {
  let token = null;
  window.grecaptcha.enterprise.ready(() => {
    window.grecaptcha.enterprise
      .execute(process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY as string, {
        action: 'submit',
      })
      .then(rt => {
        token = rt;
      });
  });
  while (!token) {
    await new Promise(r => setTimeout(r, 100));
  }
  return token;
};
