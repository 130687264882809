import { BLOCKS } from '@contentful/rich-text-types';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema, RJSFValidationError } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { contentfulSchema } from '@wr/web-shared';
import { Container as UIContainer } from '@wr/web-ui';
import { useRouter } from 'next/router';
import React, { useMemo, useRef, useState } from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { HONEYPOT_FIELD_NAME } from '@/constants/web-form';

import { Button } from '../button';
import { useStyles } from './web-form.styles';
import { getRecpatchaToken } from './web-form.utils';

export function WebForm({
  sys,
  name,
  header,
  description,
  disclaimer,
  jsonSchema,
  uiSchema,
  submitButtonLabel,
  successPage,
}: contentfulSchema.WebFormFragment) {
  const router = useRouter();
  const classes = useStyles();
  const formContainerRef = useRef<HTMLDivElement>(null);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [isHoneypotSet, setIsHoneypotSet] = useState<boolean>(false);

  const isFormDisabled = useMemo(() => isSubmitting || isSubmitted, [
    isSubmitting,
    isSubmitted,
  ]);

  const richTextContent = description?.json
    ? ({
        ...description,
        options: {
          [BLOCKS.PARAGRAPH]: {
            paragraph: true,
            variant: 'body2',
          },
          [BLOCKS.LIST_ITEM]: {
            variant: 'body2',
          },
        },
      } as RichTextProps)
    : null;

  const onSubmit = async (data: IChangeEvent) => {
    // set formData again because RJSF clears it on submit
    setFormData(data.formData);

    setErrorMessage(null);
    setIsSubmitting(true);
    setIsSubmitted(false);

    const { formData } = data;

    // Add honeypot field to form data
    const updatedFormData = {
      ...formData,
      [HONEYPOT_FIELD_NAME]: isHoneypotSet,
    };

    try {
      const token = await getRecpatchaToken();

      const response = await fetch('/api/form-submit', {
        method: 'POST',
        body: JSON.stringify({
          formData: updatedFormData,
          formId: sys.id,
          token,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      setIsSubmitted(true);

      router.push(`/${successPage?.slug || ''}`);
    } catch (error) {
      setErrorMessage('Failed to submit the data. Please try again.');
    } finally {
      setIsSubmitting(false);
      scrollIntoView();
    }
  };

  const onError = (errors: RJSFValidationError[]) => {
    if (errors.length === 0) return;
    scrollIntoView();
  };

  const scrollIntoView = () => {
    const form = formContainerRef.current;
    if (form) {
      form.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Honeypot field to check if the form is submitted by a bot
  const HoneypotCheckbox = () => (
    <FormControlLabel
      id={`root_${HONEYPOT_FIELD_NAME}`}
      label="I agree to the privacy policy"
      className={classes[HONEYPOT_FIELD_NAME]}
      control={
        <Checkbox
          checked={isHoneypotSet}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setIsHoneypotSet(event.target.checked)
          }
        />
      }
    />
  );

  return (
    <section id={name as string} className={classes.section}>
      <UIContainer maxWidth="xl">
        <Grid
          data-testid="web-form-grid"
          container
          className={classes.gridContainer}
        >
          <Grid data-testid="web-form-grid-item" item xs={12} sm={6}>
            <Typography
              variant="h2"
              className={classes.header}
              data-testid="web-form-header"
            >
              {header}
            </Typography>
            {richTextContent && (
              <div data-testid="web-form-description">
                <RichTextContentRenderer {...richTextContent} />
              </div>
            )}
            {disclaimer && (
              <Typography
                variant="body1"
                className={classes.disclaimer}
                data-testid="web-form-disclaimer"
              >
                {disclaimer}
              </Typography>
            )}
            <div ref={formContainerRef}>
              {errorMessage && (
                <div className={classes.formError}>{errorMessage}</div>
              )}
              <div data-testid="web-form-rjsf-container">
                <Form
                  schema={jsonSchema as RJSFSchema}
                  uiSchema={uiSchema as RJSFSchema}
                  validator={validator}
                  onSubmit={onSubmit}
                  disabled={isFormDisabled}
                  onError={onError}
                  formData={formData}
                >
                  <HoneypotCheckbox />
                  <Button
                    data-testid={'web-form-button'}
                    variant="contained"
                    color="primary"
                    size="medium"
                    className={classes.submitButton}
                    type="submit"
                    disabled={isFormDisabled}
                  >
                    {isSubmitting
                      ? `${submitButtonLabel}...`
                      : submitButtonLabel}
                  </Button>
                </Form>
              </div>
            </div>
          </Grid>
        </Grid>
      </UIContainer>
    </section>
  );
}
